.about {
  @include margin(20, 60, 'top');

  &__row {
    @include mq(bp-992, max) {
      @include flex-column-center;
    }
  }

  &__left-column {
    @include padding(20, 70, 'right');

    @include mq(bp-992, max) {
      padding-right: 0;
    }
  }

  &__right-column {
    @include mq(bp-992) {
      @include padding(20, 70, 'left');
    }
  }

  &__picture {
    display: flex;
    align-items: flex-start;

    @include mq(bp-414, max) {
      margin-right: -25px;
      margin-left: -25px;
    }
  }

  &__title {
    text-align: left;

    @include mq(bp-992, max) {
      margin-top: 45px;
      text-align: center;
    }
  }

  &__desc {
    @include fontr(14px, 20px);

    color: $black;
    line-height: 1.2;

    @include mq(bp-992, max) {
      text-align: center;
    }
  }

  &__col-right {
    @include mq(bp-992) {
      @include padding(25, 70, 'left');

      margin-top: 150px;
    }

    .about__box {
      @include mq(bp-992) {
        @include padding(25, 70, 'left');
      }
    }
  }

  &__col-left {
    @include mq(bp-992) {
      @include padding(20, 70, 'right');
    }
  }

  &__box {
    @include margin(70, 140, 'bottom');

    @include mq(bp-992) {
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }

    @include mq(bp-992, max) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:nth-child(1) {
        @include margin(70, 140, 'top');
      }
    }

    &-heading {
      position: relative;

      @include mq(bp-992, max) {
        display: inline-flex;
      }
    }

    &-title {
      text-align: left;

      @include mq(bp-992, max) {
        text-align: center;
      }
    }

    &-img {
      position: absolute;
      left: 0;
      top: 0;
      max-width: 100px;
      max-height: 100px;
      transform: translate(-75%, -30%);

      @include mq(bp-992, max) {
        display: none;
      }
    }

    &-desc {
      @include fontr(14px, 20px);

      color: $black;

      @include mq(bp-992, max) {
        text-align: center;
        width: 100%;

        ul {
          margin-left: 25px;
        }
      }

      pre {
        color: $green;
        font-family: $base-font;
        margin-bottom: 5px;
      }
    }
  }

  &__awards {
    @include padding(25, 70, 'left');
    @include padding(25, 50, 'right');
    @include padding(45, 45, 'top');
    @include padding(50, 60, 'bottom');
    @include margin(70, 140, 'bottom');

    // margin-top: 120px;
    position: relative;
    border: 1px solid $base-violet;
    background-color: #dfe0e8;
    border-radius: 5px;

    @include mq(bp-414, max) {
      border: none;
      border-radius: 0;
      margin-right: -25px;
      margin-left: -25px;
    }

    &-title {
      display: flex;
      max-width: 300px;

      @include mq(bp-992, max) {
        text-align: center;
        justify-content: center;
        max-width: initial;
      }
    }
  }

  &__order {
    @include img(60px, 110px, 'width');
    @include img(60px, 110px, 'height');

    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    background-color: $base-violet;
    transform: translate(-50%, -50%);

    @include mq(bp-992, max) {
      left: 50%;
    }

    &::after {
      @include pseudo();
      @include img(23px, 40px, 'width');
      @include img(28px, 50px, 'height');

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url(../../images/svg/order.svg);
      background-size: contain;
      background-position: center;
    }
  }
}
