.certificates {
  @include margin(60, 80, 'top');

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 50px;

    @include mq(bp-414) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
      row-gap: 30px;
    }

    @include mq(bp-768) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
    }

    @include mq(bp-1024) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include mq(bp-1536) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &__box {
    pointer-events: none;

    &:hover {
      .certificates__box-image {
        transform: scale(1.05) translateZ(0);
      }
      .certificates__box-title {
        color: $green;
      }
    }

    &-picture {
      // box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
      display: flex;
      overflow: hidden;
      pointer-events: initial;
    }

    &-image {
      transition: transform 0.3s;
    }

    &-title {
      @include fontr(15px, 18px, 500);

      pointer-events: initial;
      color: $black;
      text-align: center;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      padding: 0 5px;
      transition: color 0.3s;
    }
  }
}
