.block-padding {
  color: $black;
  @include fontr(14px, 20px, 400);
  line-height: 1.4;
  padding: 15px 0;
  @include mq(bp-1280) {
    padding: 35px 0;
  }
  @include mq(bp-1536) {
    padding: 50px 0;
  }
}
.block-padding-bottom {
  padding-bottom: 50px;
}
.block-padding-top {
  padding-top: 50px;
}
.block-details {
  position: relative;
  background-color: #dfe0e8;
  padding: 40px 25px;
  margin-left: -25px;
  margin-right: -25px;
  color: $black;

  &__title {
    @include fontr(20px, 40px, 700);

    @include mq(bp-992, max) {
      text-align: center;
      margin-top: 10px;
    }
  }
  &.global-list ul {
    padding-left: 20px;
  }
  @include mq(bp-414) {
    padding: 40px 50px;
    margin: 0;
  }
  @include mq(bp-768) {
    border: 1px solid $base-violet;
    border-radius: 5px;
  }
  @include mq(bp-992) {
    &.global-list ul {
      padding-left: 30px;
    }
    padding: 40px 80px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-self: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -31px;
    padding: 12px;
    @include mq(bp-992) {
      padding: 20px;
      left: auto;
      right: 5vw;
      top: -45px;
    }
    background-color: $base-violet;
    border-radius: 50%;
    img {
      margin: 0 auto;
    }
    @include img(60px, 98px, 'width');
    @include img(60px, 98px, 'height');
  }
}

.two-cols {
  display: flex;
  position: relative;
  @include mq(bp-992, max) {
    text-align: center;
  }

  &__title-wrapper {
    position: relative;
  }
  &__title-icon {
    position: absolute;

    @include img(60px, 98px, 'width');
    @include img(60px, 98px, 'height');

    top: -10px;
    left: -50px;
    @include mq(bp-992) {
      top: -20px;
      left: -80px;
    }
  }
  &__left {
    position: relative;
    @include mq(bp-992, max) {
      padding: 0 15px;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  &__right-column {
    @include mq(bp-992) {
      padding-left: 5vw;
    }
  }

  .block-details {
    p {
      margin-bottom: 0;
      font-weight: 600;
    }
    padding: 40px 25px;
    @include mq(bp-414) {
      padding: 60px 30px;
    }
    @include mq(bp-992) {
      margin-top: 30px;
      padding: 60px 30px;
    }
  }
}

// YOUTUBE

.videoWrapper {
  position: relative;
  // padding-bottom: 56.25%; /* 16:9 */
  padding-bottom: 50%; /* 16:9 */
  height: 0;
  @include mq(bp-414, max) {
    margin-left: -25px;
    margin-right: -25px;
    padding-bottom: 66.25%;
  }
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.play-video {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  font-size: 0;
  left: 0;
  right: 0;
  z-index: 99;
  margin: 0 auto;
  background-image: url(#{$base-path}/icons/play.svg);
  background-position: center;
  background-size: contain;
  width: 90px;
  height: 90px;
  @include mq(bp-768) {
    width: 100px;
    height: 100px;
  }
  @include mq(bp-1280) {
    width: 150px;
    height: 150px;
  }
  transition: all 0.3s;
  &:hover {
    transform: translateY(-50%) scale(1.1);
  }
}
.video-cover {
  position: absolute;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: $base-violet;
  overflow: hidden;
  &::before {
    @include pseudo();
    width: 100%;
    height: 100%;
    background-color: rgba(74, 58, 161, 0.45);
  }
  img {
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; /* or object-fit: contain; */
  }
}
.text-default {
  color: $black;
  @include fontr(14px, 20px, 400);
  line-height: 1.4;

  margin-bottom: 15px;
  @include mq(bp-992) {
    margin-bottom: 30px;
  }
}

.article {
  ul,
  ol {
    padding-left: 20px;

    @include mq(bp-992) {
      padding-left: 25px;
    }
  }
}
