@mixin repeat {
  border-color: $green;

  &:hover {
    background-color: $green;

    .offer__item-title,
    .offer__item-desc {
      color: $white;
    }

    .offer__item-btn {
      color: $green;
      background-color: $white;
    }
  }

  .offer__item-title {
    color: $green;
  }

  .offer__item-btn {
    background-color: $green;
  }
}

.offer {
  @include padding(70, 140, 'top');

  background-color: #fafafa;

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 15px;

    @include mq(bp-768) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(bp-1024) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mq(bp-1280) {
      grid-gap: 30px;
    }
  }

  &__item {
    @include padding(20, 30, 'top');
    @include padding(20, 30, 'bottom');
    @include padding(18, 25, 'left');
    @include padding(18, 25, 'right');

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid $base-violet;
    border-radius: 5px;
    background-color: $white;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: $base-violet;

      .offer__item-title,
      .offer__item-desc {
        color: $white;
      }

      .offer__item-btn {
        color: $base-violet;
        background-color: $white;
      }
    }

    &-title {
      @include fontr(22px, 30px, 700);

      color: $base-violet;
      line-height: 1.3;
      max-width: 330px;
      transition: color 0.3s;
    }

    &-desc {
      @include fontr(14px, 16px, '400');

      color: $black;
      margin-top: 10px;
      margin-bottom: 15px;
      transition: color 0.3s;
    }

    &-btn {
      @include fontr(13px, 14px, 700);
      @include padding(13, 15, 'top');
      @include padding(13, 15, 'bottom');
      @include padding(28, 30, 'left');
      @include padding(28, 30, 'right');

      border-radius: 5px;
      color: $white;
      border: none;
      background-color: $base-violet;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.3s;
    }

    @include mq(bp-768, max) {
      &:nth-child(6n + 4),
      &:nth-child(6n + 5),
      &:nth-child(6n + 6) {
        @include repeat;
      }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      &:nth-child(4n + 3),
      &:nth-child(4n + 4) {
        @include repeat;
      }
    }
    @media (min-width: 1024px) {
      &:nth-child(6n + 4),
      &:nth-child(6n + 5),
      &:nth-child(6n + 6) {
        @include repeat;
      }
    }
  }
}
