/******************/
/* ~*~ Config ~*~ */
/******************/
@import './base/core';
@import './base/reset';

/******************/
/* ~*~ NPM ~*~ */
/******************/
@import '~aos/dist/aos.css';
@import '~simplelightbox/dist/simple-lightbox.css';
/******************/
/* ~*~ Global ~*~ */
/******************/
@import './global/colors';
@import './global/form';
@import './global/keyframes';

/******************/
/* ~*~ Mixins ~*~ */
/******************/
@import './base/rest';
@import './base/responsive';
@import './base/grid';
@import './base/flex';
@import './base/font';
@import './base/retina';

/**********************/
/* ~*~ Components ~*~ */
/**********************/
@import './components/global';
@import './components/nav';
@import './components/footer';
@import './components/texts';
@import './components/buttons';
@import './components/cookies';

/******************/
/* ~*~ Shared ~*~ */
/******************/
@import './shared/social-media';
@import './shared/cases';
@import './shared/also-check';
@import './shared/above-footer';
@import './shared/text-header';

/******************/
/* ~*~ Layout ~*~ */
/******************/
@import './pages/homepage';
@import './pages/timeline';
@import './pages/faq';
@import './pages/pricelist';
@import './pages/contact';
@import './pages/certificates';
@import './pages/error';

/******************/
/* ~*~ Blocks ~*~ */
/******************/
@import './blocks/global';
@import './blocks/video';
@import './pages/about';
@import './pages/offer';

html {
  margin: 0;
  padding: 0;
}
body {
  position: relative;
  min-width: 320px;
  overflow-x: hidden;

  font-family: $base-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow-x: hidden;
}

.main-title {
  padding: 20px 0;
  .h1 {
    margin-bottom: 15px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @include mq(bp-992) {
      flex-direction: row;
    }
  }
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @include mq(bp-992) {
      flex-direction: row;
      align-items: center;
      margin-right: -10px;
      justify-content: flex-end;
    }
    .btn {
      margin: 5px 10px;
      text-transform: uppercase;
      @include mq(bp-1536) {
        margin: 5px 10px;
      }
    }
  }
}
