.text-header {
  @include padding(30, 70, 'bottom');

  &.cases-header {
    .text-header__desc {
      @include mq(bp-992) {
        padding-left: 30px;
      }
    }
  }

  &__row {
    @include mq(bp-992) {
      columns: 2;
      column-gap: 60px;
    }

    @include mq(bp-992, max) {
      @include flex-column-center;
    }
  }

  &__title {
    @include mq(bp-992) {
      text-align: left;
    }
  }

  &__desc {
    @include fontr(14px, 20px, 400);

    color: $black;
    line-height: 1.2;

    @include mq(bp-992, max) {
      text-align: center;
    }
  }
}