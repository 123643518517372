.error-page {
  position: relative;

  &__picture {
    display: flex;
    width: 100%;
    min-height: 550px;
    position: relative;

    &::after {
      @include pseudo();

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.25;
      background-color: $white;
    }
  }

  &__img {
    width: 100%;
    object-fit: cover;

    @include mq(bp-414, max) {
      object-position: left;
    }
  }

  &__box {
    @include flex-column-center;

    position: absolute;
    top: 10%;
    right: 15%;

    @include mq(bp-768, max) {
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
    }
  }

  &__title {
    @include fontr(24px, 50px, 700);

    color: $black;
    margin-bottom: 15px;
    text-align: center;
  }

  &__subtitle {
    @include fontr(13px, 18px, 400);
    @include margin(30, 45, 'bottom');

    color: $black;
    text-align: center;
  }
}
