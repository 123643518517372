.accordion {
  @include mq(bp-768, max) {
    margin-top: 15px;
    margin-left: -25px;
    margin-right: -25px;
  }
  @include mq(bp-992) {
    margin-top: 45px;
  }

  &__single {
    margin-bottom: 5px;
    p:last-child {
      margin-bottom: 0;
    }
  }

  &__button {
    @include fontr(14px, 20px, 600);

    background-color: $green;
    width: 100%;
    color: #fff;
    border: none;
    text-align: left;
    transition: padding 0.3s;
    padding: 25px;

    &:hover {
      padding-left: 50px;
    }

    @include mq(bp-768, max) {
      padding: 20px 45px 20px 20px;

      &:hover {
        padding-left: 35px;
      }
    }
    position: relative;
    cursor: pointer;
    // outline-color: $green;
    outline: none;

    &::before,
    &::after {
      @include pseudo();
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #fff;
      transition: all 0.3s;
    }
    &.is-active {
      background-color: $base-violet;
      &::before {
        height: 0;
      }
    }

    &::before {
      width: 3px;
      height: 18px;
      right: 38px;
    }

    &::after {
      width: 19px;
      height: 3px;
    }
    @include mq(bp-768, max) {
      &::before {
        width: 2px;
        height: 13px;
        right: 25px;
      }

      &::after {
        width: 13px;
        height: 2px;
        right: 20px;
      }
    }
  }

  &__content {
    background-color: #ebecf2;
    padding: 25px;
    @include fontr(14px, 18px);
    line-height: 1.5;
  }
}
