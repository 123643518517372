@keyframes fGTZxa {
  0% {
    transform: translate(0px, 0px);
  }
  99% {
    transform: translate(100%, 0px);
  }
  100% {
    transform: translate(-80%, -100%);
  }
}

@keyframes ktYJee {
  0% {
    transform: translate(-100%, -100%);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
