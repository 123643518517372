body.nav-open {
  overflow: hidden;
}

.fixed-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

/* navbar regular css start */
.navbar-area {
  background: $base-violet;
  color: #fff;
  padding: 17px 0;

  @include mq(bp-1440) {
    padding: 20px 0;
  }
}

.site-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

a.site-logo {
  margin-right: 25px;

  @include mq(bp-414, max) {
    width: 136px;
  }

  @include mq(bp-768, max) {
    width: 156px;
  }

  @include mq(bp-1366, max) {
    max-width: 150px;
  }

  @include mq(bp-1536) {
    min-width: 210px;
  }
}

.nav-main {
  list-style: none;
  display: flex;
  align-items: center;
  z-index: 2;
}

.nav-main li a {
  @include mq(bp-992, max) {
    color: $base-violet;
    font-size: 1.7em;
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #e4e5eb;
    font-weight: 600;
  }

  @include mq(bp-992) {
    font-size: 1.2rem;
    padding: 20px 15px;
    color: #fff;
  }

  @include mq(bp-1280) {
    font-size: 1.2rem;
    padding: 20px 15px;
  }

  @include mq(bp-1440) {
    font-size: 1.4rem;
    padding: 20px 15px;
  }

  @include mq(bp-1536) {
    font-size: 1.6em;
    padding: 20px;
  }

  display: block;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s;
  @include letter-spacing(25);

  &:hover {
    color: $green;
  }
}

.nav-right {
  display: flex;
  align-items: center;

  @include mq(bp-992, max) {
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 15px;
  }

  padding: 0;

  li {
    display: flex;

    @include mq(bp-992, max) {
      padding: 15px 0;

      &:first-child {
        justify-content: center;
        width: 100%;
      }

      &:nth-child(2) {
        flex: 3;
      }

      &:nth-child(3) {
        flex: 1;
      }
    }

    @include mq(bp-992) {
      margin-right: 25px;
    }

    @include mq(bp-1280) {
      margin-right: 18px;
    }

    @include mq(bp-1366) {
      margin-right: 35px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.nav-call {
  display: flex;
  align-items: center;
  text-transform: uppercase;

  @include mq(bp-992, max) {
    color: $base-violet;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 40px;
    position: relative;
  }

  &:hover {
    .number {
      &::before {
        animation-name: phone;
        animation-duration: 0.15s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
    }
  }

  @keyframes phone {
    50% {
      transform: translate(3px, -50%) rotate(2deg) translateZ(0);
    }
    100% {
      transform: translate(-3px, -50%) rotate(-2deg) translateZ(0);
    }
  }

  .number {
    display: flex;
    font-size: 2.4rem;
    font-weight: 500;

    @include mq(bp-992) {
      position: relative;
      @include letter-spacing(25);
      font-size: 1.5rem;
      padding-left: 30px;
      margin-left: 10px;
      font-weight: 400;
    }

    @include mq(bp-1536) {
      font-size: 2.4rem;
      padding-left: 40px;
      margin-left: 15px;
    }

    &::before {
      @include pseudo();
      top: 50%;
      transform: translateY(-50%);
      background-image: url(#{$base-path}/icons/phone.svg);
      width: 30px;
      height: 30px;
      left: 0px;

      @include mq(bp-992) {
        width: 20px;
        height: 20px;
      }

      @include mq(bp-1280) {
        width: 25px;
        height: 25px;
      }

      @include mq(bp-1536) {
        width: 30px;
        height: 30px;
      }

      background-size: contain;
      background-position: center;
    }
  }

  span {
    font-size: 1.2rem;

    @include mq(bp-992) {
      display: none;
    }

    @include mq(bp-1280) {
      display: block;
      font-size: 1rem;
    }

    @include mq(bp-1536) {
      font-size: 1.2rem;
    }

    font-weight: 500;
  }
}

.nav-toggler {
  border: none;

  background-color: transparent;
  cursor: pointer;
  height: 33px;
  width: 33px;

  @include mq(bp-414) {
    height: 45px;
    width: 45px;
    padding: 10px;
  }

  display: none;
  background-color: #fff;
  border-radius: 50%;
}

.nav-toggler span,
.nav-toggler span:before,
.nav-toggler span:after {
  width: 18px;
  height: 2px;

  @include mq(bp-414) {
    width: 24px;
    height: 3px;
  }

  background-color: $green;
  display: block;
  transition: 0.3s;
}

.nav-toggler span:before {
  content: '';
  transform: translateY(-6px);

  @include mq(bp-414) {
    transform: translateY(-7px);
  }
}

.nav-toggler span:after {
  content: '';

  transform: translateY(4px);

  @include mq(bp-414) {
    transform: translateY(4px);
  }
}

.nav-toggler.toggler-open span {
  background-color: transparent;
}

.nav-toggler.toggler-open span:before {
  transform: translateY(0px) rotate(45deg);
}

.nav-toggler.toggler-open span:after {
  transform: translateY(-2px) rotate(-45deg);

  @include mq(bp-414) {
    transform: translateY(-3px) rotate(-45deg);
  }
}

.nav-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .btn__with-arrow {
    font-size: 1.5rem;
    text-transform: inherit;

    @include mq(bp-992) {
      display: none;
    }

    @include mq(bp-1280) {
      font-size: 1.4rem;
      padding: 8px 15px;
      padding-right: 35px;
      display: block;
    }

    @include mq(bp-1536) {
      padding: 15px 25px;
      padding-right: 40px;
      font-size: 1.5rem;
    }
  }
}

/* mobile breakpoint start */
@media screen and (max-width: 992px) {
  /* navbar css for mobile start */
  .nav-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .site-navbar .nav-container {
    position: absolute;
    width: 100%;
    left: 0;
    top: 67px;
    border-top: 1px solid #444;
    background-color: #fff;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    display: flex;
    flex-direction: column;
    z-index: 3;

    @include mq(bp-414) {
      top: 78px;
    }

    &.open {
      max-height: calc(100vh - 67px);
      overflow-y: auto;
    }

    .nav-main {
      display: flex;
      flex-direction: column;
    }
  }

  .site-navbar ul li {
    width: 100%;
    text-align: center;
  }

  .site-navbar .nav-main li a {
    padding: 20px;
  }

  .site-navbar ul li a:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  /* navbar css for mobile end */
}

/* mobile breakpoint end */

.lang-switch {
  position: relative;
  text-transform: uppercase;
  font-size: 1.6rem;
  padding: 7px 15px;
  background-color: $base-violet;
  font-weight: 600;
  transform: scale(1) translateZ(0);
  transition: transform 0.3s;

  &:hover {
    transform: scale(0.95) translateZ(0) translate3d(0, 0, 0);
  }

  @include mq(bp-992) {
    font-weight: 400;
    background-color: $green;
    padding: 12px 15px;
  }

  &::after {
    @include pseudo();
    left: 0;
    bottom: -7px;
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $base-violet;

    @include mq(bp-992) {
      bottom: -10px;
      border-left: 0px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $green;
    }
  }
}

.topbar-toggler {
  color: $green;
  background-color: #fff;
  padding: 20px;
  font-size: 1.7em;
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e4e5eb;
  border: none;
  font-weight: 600;
  z-index: 2;
  text-transform: uppercase;
  outline-color: $green;

  &.open {
    span::after {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  span {
    position: relative;

    &::after {
      @include pseudo();
      width: 12px;
      height: 9px;
      background-size: contain;
      top: 50%;
      transform: translateY(-50%);
      right: -30px;
      transition: all 0.3s;
      //background-image: url(#{$base-path}/icons/arrow_down_green.svg);
      background-image: url(#{$base-path}/icons/arrow_down_green.svg);
    }
  }

  @include mq(bp-992) {
    display: none;
  }
}

.home .topbar {
  background-color: #fff;
}

.topbar {
  background-color: #f2f2f2;
  padding: 15px;
  @include letter-spacing(20);

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-sm {
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
    margin-left: auto;

    &__list {
      @include flex-center;
    }

    &__item {
      width: auto;
      margin-right: 10px;
      transition: opacity 0.3s;

      &:nth-last-child(1) {
        margin-right: 0;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  li {
    margin: 0 5px;
  }

  &__items {
    display: flex;
    justify-content: center;
    margin-left: auto;
    text-transform: uppercase;

    @include mq(bp-992, max) {
      background-color: #e4e5eb;
      flex-direction: column;
      max-height: 0;
      transition: max-height 0.6s ease;
      visibility: hidden;
      width: 100%;

      a {
        opacity: 0;
        transition: all 0.3s;
      }

      &.open {
        max-height: 400px;
        display: flex;
        visibility: visible;

        a {
          transition: opacity 0.9s;
          opacity: 1;
        }
      }

      li,
      a {
        display: flex;
      }
    }

    a {
      color: $base-violet;
      position: relative;
      z-index: 2;
      @include mq(bp-992) {
        &::after {
          @include pseudo();
          bottom: -3px;
          height: 0%;
          background-color: $base-violet;
          z-index: -1;
          opacity: 0.08;
          width: 0%;
          left: 0;
          right: 0;
          margin: 0 auto;
          transition: all 0.3s;
        }
      }

      @include mq(bp-992, max) {
        width: 100%;
        padding: 20px 15px;
        font-size: 1.7rem;
        display: flex;
        text-align: center;
        justify-content: center;
        background-color: #e4e5eb;
        border-bottom: 1px solid rgba(74, 58, 161, 0.1);
      }

      padding: 5px 10px;

      &:hover {
        &::after {
          width: 80%;
          height: 70%;
        }
      }
    }

    @include mq(bp-992) {
      font-size: 1.1rem;
    }

    @include mq(bp-1280) {
      font-size: 1.2rem;
    }

    @include mq(bp-1440) {
      font-size: 1.4rem;
    }

    @include mq(bp-1536) {
      font-size: 1.5em;
    }
  }
}
//Mobile
.navbar-sm {
  @include mq(bp-992, max) {
    order: 2;
    margin-bottom: 30px;
  }
}
.navbar-sm__list {
  @include mq(bp-992, max) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.navbar-sm__item {
  @include mq(bp-992, max) {
    width: auto !important;
    margin: 0 10px;
  }
}
