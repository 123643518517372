.social-media {
  @include padding(40, 100, 'top');
  @include padding(40, 100, 'bottom');

  background-color: $gray-100;
  overflow: hidden;

  &__row {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 200px));
    grid-auto-rows: 1fr;
    justify-content: center;
    gap: 15px;

    @include mq(bp-992) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include mq(bp-1280) {
      column-gap: 40px;
      row-gap: 0;
    }
  }

  &__box {
    @include flex-column-center;

    padding: 11px 15px;
    transition: opacity 0.3s;

    @include mq(bp-992) {
      padding: 23px 33px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__fb {
    background-color: #4a7ec3;

    &-icon {
      @include img(40px, 70px, 'width');
      @include img(40px, 70px, 'height');
    }
  }

  &__yt {
    background-color: #e03c3c;

    &-icon {
      @include img(80px, 140px, 'width');
    }
  }

  &__google {
    background-color: #4a3aa1;

    &-icon {
      @include img(80px, 140px, 'width');
    }
  }

  &__famous {
    align-items: flex-start;
    background-color: #46bfac;

    &-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 10px;
    }

    &-number {
      @include fontr(18px, 40px, 700);
      @include tracking(20);

      color: $white;
    }

    &-stars {
      @include img(60px, 130px, 'width');
    }

    &-link {
      @include padding(3, 6, 'top');
      @include padding(3, 6, 'bottom');
      @include padding(9, 18, 'left');
      @include padding(9, 18, 'right');
      @include fontr(8px, 14px, 700);

      color: $black;
      background-color: $white;
    }

    &-desc {
      @include fontr(8px, 14px, 400);

      color: $white;

      @include mq(bp-992, max) {
        max-width: 100px;
      }
    }

    &-line {
      height: 1px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.09);
      outline: none;
      border: none;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    &-icon {
      @include img(80px, 180px, 'width');
    }
  }
}
