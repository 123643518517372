@import '~swiper/swiper-bundle.css';
@import './homepage_slider';
/* --------- */
/* About me */
/* -------- */
.about-me {
  @include mq(bp-768) {
    margin-top: 60px;
  }

  &__row {
    @include mq(bp-992, max) {
      justify-content: center;
    }
  }

  &__picture {
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    position: relative;

    @include mq(bp-992, max) {
      max-height: 300px;
      max-width: 400px;
    }

    @include mq(bp-414, max) {
      margin-right: -15px;
      margin-left: -15px;
    }

    &::after {
      @include pseudo();

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(172, 175, 191, 0.2);
    }
  }

  &__image {
    width: 100%;
    object-fit: cover;

    @include mq(bp-992, max) {
      object-position: top;
    }
  }

  &__texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    @include mq(bp-992) {
      @include padding(60, 100, 'left');

      margin-top: 0;
      align-items: flex-start;
    }
  }

  &__big-txt {
    @include fontr(24px, 40px, 700);

    margin-bottom: 20px;
    color: $black;

    @include mq(bp-992, max) {
      text-align: center;
    }

    p {
      margin-bottom: 0.5em;
    }
  }

  &__small-txt {
    @include fontr(14px, 18px, 400);
    @include margin(20, 25, 'bottom');

    color: $black;
    max-width: 800px;

    @include mq(bp-992, max) {
      text-align: center;
    }
  }
}

/* ----- */
/* Help */
/* ---- */
.help {
  overflow: hidden;

  &__wrapper {
    @include mq(bp-414, max) {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  &__row {
    @include margin(15, 90, 'top');

    display: flex;
    align-items: flex-start;
    position: relative;
    // pointer-events: none;

    @include mq(bp-992, max) {
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 120px;
    }

    @include mq(bp-768, max) {
      @include margin(15, 90, 'top');

      margin-bottom: 0;
    }

    &:nth-child(1) {
      margin-top: 0;
    }

    &:nth-child(even) {
      .help__row-picture {
        order: -1;
      }
    }

    &:hover {
      .help__row-texts {
        background-color: $base-violet;
      }

      .help__row-title,
      .help__row-desc {
        color: $white;
      }
    }

    &-texts {
      @include padding(40, 70, 'top');
      @include padding(40, 70, 'bottom');
      @include padding(25, 130, 'right');
      @include padding(25, 130, 'left');

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
      color: $black;
      // pointer-events: none;
      background-color: $gray-100;
      transition: all 0.7s;

      @include mq(bp-414, max) {
        align-items: center;
      }

      @include mq(bp-992) {
        width: 60%;
      }
    }

    &-title {
      @include fontr(24px, 42px, 700);

      // pointer-events: initial;
      color: $black;
      cursor: pointer;
      margin-bottom: 25px;

      @include mq(bp-414, max) {
        text-align: center;
      }
    }

    &-desc {
      @include fontr(14px, 17px);
      @include margin(30, 40, 'bottom');

      color: $black;
      line-height: 1.3;

      @include mq(bp-414, max) {
        text-align: center;
      }
    }

    &-link {
      // pointer-events: initial;

      &:hover {
        opacity: 1;
      }
    }

    &-picture {
      display: flex;
      width: 40%;
      align-self: stretch;
      // pointer-events: initial;

      @include mq(bp-992, max) {
        order: -1;
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(50%, 50%);
      }

      @include mq(bp-768, max) {
        position: relative;
        transform: initial;
        width: 100%;
        max-height: 300px;
      }
    }

    &-image {
      width: 100%;
      object-fit: cover;
    }
  }
}

/* ----------- */
/* Diagnostic */
/* ---------- */
.diagnostic {
  overflow: hidden;

  &__row {
    @include flex-center;

    @include mq(bp-992, max) {
      flex-direction: column;
    }
  }

  &__title {
    @include fontr(36px, 92px, 700);

    color: $black;
    text-align: center;

    @include mq(bp-992) {
      text-align: right;
      width: 50%;
    }
  }

  &__desc {
    @include fontr(14px, 18px, 400);

    color: $black;
    line-height: 1.4;
    text-align: center;

    @include mq(bp-992, max) {
      margin-top: 25px;
      max-width: 550px;
    }

    @include mq(bp-992) {
      text-align: left;
      width: 50%;
      margin-left: 60px;
    }
  }

  &__list {
    @include margin(100, 150, 'top');

    display: grid;
    grid-template-columns: 1fr;
    column-gap: 35px;
    row-gap: 105px;

    @include mq(bp-414, max) {
      margin-right: -15px;
      margin-left: -15px;
    }

    @include mq(bp-768) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(bp-992) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 50px;
      row-gap: 0;
    }

    @include mq(bp-1280) {
      column-gap: 70px;
    }

    @include mq(bp-1536) {
      column-gap: 90px;
    }
  }

  &__item {
    pointer-events: none;

    &:hover {
      .diagnostic__item-image {
        transform: scale(1.05) translateZ(0);
      }
    }

    &-box {
      position: relative;
    }

    &-category {
      @include fontr(14px, 20px, 700);
      @include img(110px, 170px, width);
      @include img(110px, 170px, height);
      @include flex-center;

      padding: 20px;
      color: $white;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $base-violet;
      pointer-events: initial;
    }

    &-picture {
      display: flex;
      position: relative;
      overflow: hidden;
      pointer-events: initial;

      &::after {
        @include pseudo();

        width: 100%;
        height: 100%;
        background-color: rgba(172, 175, 191, 0.2);
      }
    }

    &-image {
      width: 100%;
      object-fit: cover;
      transform: translateZ(0) scale(1);
      transition: transform 0.3s;
    }

    &-desc {
      @include fontr(14px, 18px, 400);
      @include margin(25, 40, 'top');

      color: $black;
      text-align: center;
      pointer-events: none;

      @include mq(bp-414, max) {
        padding: 0 15px;
      }
    }
  }
}

.call-skype {
  background-color: $base-violet;
  padding: 32px 15px;
  color: #fff;

  &--margin {
    @include margin(50, 70, 'bottom');
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq(bp-992, max) {
      flex-direction: column;
      justify-content: center;
    }
    @include mq(bp-1366) {
      max-width: 1366px !important;
    }
  }
  &__title {
    @include fontr(24px, 48px, 600);
    display: block;
    position: relative;
    margin-bottom: 15px;
    @include mq(bp-992) {
      padding-left: 68px;
      &::before {
        @include pseudo();
        width: 58px;
        height: 58px;
        background-size: contain;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        transition: all 0.3s;
        background-image: url(#{$base-path}/icons/skype_white.svg);
        @include mq(bp-1280) {
          width: 44px;
          height: 44px;
        }
        @include mq(bp-1536) {
          width: 54px;
          height: 54px;
        }
      }
    }
  }
}
