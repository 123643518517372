.title {
  @include fontr(36px, 50px, 700);
  @include margin(40, 50, 'bottom');
  line-height: 1.2;
  color: $black;
  text-align: center;

  &--xl {
    @include fontr(36px, 70px, 700);
  }

  &--md {
    @include fontr(24px, 50px, 700);
    @include margin(25, 30, 'bottom');

    line-height: 1.1;
  }
}
.h1 {
  @include fontr(36px, 96px, 700);
  color: $black;
  line-height: 1;
  @include mq(bp-768, max){
    text-align: center;
  }
}
// @mixin subtitle($color: $title-black, $small: false) {
//   @include fontr(32px, 56px, 700);

//   font-family: $futura-bold;
//   color: $color;
//   text-transform: uppercase;
//   line-height: 1.6;

//   @include mq(bp-1920) {
//     font-size: 72px;
//   }

//   @if $small {
//     @include fontr(22px, 36px, 700);

//     line-height: 1;

//     @include mq(bp-1920) {
//       font-size: 40px;
//     }
//   }
// }

// @mixin description($color: rgba(32, 32, 32, 0.75)) {
//   @include fontr(12px, 16px, 400);
//   @include margin(35, 90, 'bottom');

//   font-family: $second-font-family;
//   color: $color;
//   max-width: 430px;
//   line-height: 1.5;
//   margin-top: 22px;
// }
// /* ----- */
// /* List  */
// /* ----- */
.global-list {
  ul {
    text-align: left;
    li {
      @include fontr(14px, 20px, 400);
      @include margin(10, 15, 'bottom');

      line-height: 1.4;
      position: relative;
      color: $black;
    }
  }

  ul {
    li::before {
      @include pseudo();

      top: 0.4em;
      left: -25px;
      width: 12px;
      height: 12px;
      background-color: $green;
      border-radius: 50%;

      @include mq(bp-992, max) {
        width: 9px;
        height: 9px;
        left: -20px;
      }
    }
  }
}
