.main-title {
  padding: 20px 0;

  .h1 {
    margin-bottom: 15px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @include mq(bp-992) {
      flex-direction: row;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @include mq(bp-992) {
      flex-direction: row;
      align-items: center;
      margin-right: -10px;
      justify-content: flex-end;
    }

    .btn {
      margin: 5px 10px;
      text-transform: uppercase;

      @include mq(bp-1536) {
        margin: 5px 10px;
      }
    }
  }
}

.breadcrumb {
  color: #acafbf;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  position: relative;
  padding: 15px 0;
  @include mq(bp-992, max) {
    display: none;
  }
  //arrow_breadcrumb
  a {
    transition: color 0.3s;
    &:hover {
      color: $base-violet;
    }
  }
  .current-item {
    font-weight: 600;
  }
  .line {
    margin-left: 20px;
    display: flex;
    flex-grow: 1;
    height: 1px;
    background-color: #acafbf;
  }

  .separator {
    display: block;
    background-image: url(#{$base-path}/icons/arrow_breadcrumb.svg);
    background-position: center;
    background-size: contain;
    margin: 0 8px;
    width: 7px;
    height: 10px;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: all 0.3s;
  &.loading {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid $base-violet;
  border-color: $base-violet transparent $base-violet transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  z-index: 101;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 1s ease-in-out;
  pointer-events: none;

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    transform: translate(-50%, -50%);
  }

  span {
    margin: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    animation: spin 2s ease infinite;

    @include mq(bp-992) {
      width: 40px;
      height: 40px;
    }

    &:first-child {
      background: #4a3aa1;
      animation-delay: -1.5s;
    }
    &:nth-child(2) {
      background: #4d9660;
      animation-delay: -1s;
    }
    &:nth-child(3) {
      background: #dfe0e8;
      animation-delay: -0.5s;
    }
    &:last-child {
      background: rgba(77, 150, 96, 0.5);
    }
  }
  @keyframes spin {
    0%,
    100% {
      transform: translate(0);
    }
    25% {
      transform: translate(160%);
    }
    50% {
      transform: translate(160%, 160%);
    }
    75% {
      transform: translate(0, 160%);
    }
  }
}
