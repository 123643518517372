.btn {
  @include fontr(14px, 18px, 600);
  @include letter-spacing(10);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  border-radius: 5px;
  background-color: $green;
  color: #fff;
  padding: 16px 30px;
  overflow: hidden;

  &__default {
    &::after,
    &::before {
      @include pseudo();

      width: 60%;
      height: 300%;
      background-color: $base-violet;
      transition: transform 0.5s;
    }

    &:after {
      right: 0;
      transform: rotate(45deg) translateX(150%);
    }

    &::before {
      left: 0;
      transform: rotate(45deg) translateX(-150%);
    }

    &:hover {
      &:after {
        transform: rotate(45deg) translateX(0%);
      }

      &:before {
        transform: rotate(45deg) translateX(0%);
      }
    }

    span {
      z-index: 2;
    }
  }

  &__with-arrow {
    padding-right: 55px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.9;

      &::after {
        transform: translate(6px, -50%);
      }
    }

    &::after {
      @include pseudo();
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 15px;
      background-image: url(#{$base-path}/icons/arrow_right.svg);
      background-size: contain;
      transition: transform 0.3s;
    }
    &.white {
      background-color: #fff;
      color: $base-violet;
      &::after {
        background-image: url(#{$base-path}/icons/arrow_right_violet.svg);
      }
    }
    &.skype {
      @include mq(bp-992, max) {
        &::after {
          width: 25px;
          height: 25px;
          background-image: url(#{$base-path}/icons/skype_violet.svg);
        }
      }
    }
  }

  &__with-document {
    padding-right: 55px;
    overflow: hidden;
    transition: opacity 0.4s, padding 0.4s;

    &:hover {
      opacity: 0.9;
      padding-left: 55px;
      padding-right: 30px;

      &::after {
        transform: translateY(-200%) translateX(200%);
      }

      &::before {
        transform: translateY(-50%) translateX(0%);
      }
    }

    &::after,
    &::before {
      @include pseudo();

      top: 50%;
      width: 18px;
      height: 23px;
      background-image: url(#{$base-path}/images/svg/document-icon.svg);
      background-size: contain;
      background-position: center;
      transition: transform 0.5s;
    }

    &::after {
      right: 15px;
      transform: translateY(-50%);
    }

    &::before {
      left: 15px;
      transform: translateY(200%) translateX(-200%);
    }
  }

  &__md {
    @include fontr(12px, 18px, 600);

    padding: 15px 25px;
  }
}
