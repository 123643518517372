.above-footer {
  position: relative;
  background-color: rgba(201, 204, 216, 0.75);

  &__picture {
    display: flex;
    min-height: 250px;

    @include mq(bp-2560) {
      margin-left: 20vw;
    }
  }

  &__image {
    object-fit: cover;
  }

  &__container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 50%;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include mq(bp-414, max) {
      justify-content: center;
    }
  }
}
