/* Cookie */
.cookie-box {
  @include padding(20, 30, 'top');
  @include padding(20, 30, 'bottom');
  @include padding(25, 40, 'left');
  @include padding(40, 70, 'right');

  position: fixed;
  z-index: 1040;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $green;
  color: $white;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateY(0px);

  @include mq(bp-768) {
    max-width: 1100px;
  }

  @include mq(bp-768, max) {
    flex-direction: column;
  }

  .cookie__column-left {
    @include fontr(10px, 13px, 400);
    @include margin(30, 50, 'right');

    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $white;
    max-width: 700px;
    line-height: 1.3;

    @include mq(bp-768, max) {
      flex-direction: column;
      text-align: center;
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  &__img {
    margin-right: 20px;

    @include mq(bp-768, max) {
      margin-right: 0;
      max-width: 50px;
      margin-bottom: 10px;
    }
  }

  .cookie__column-right {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;

    span {
      color: $green;
      background-color: $white;
      opacity: 1;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &--hide {
    opacity: 0;
    transform: translateY(100px);
    pointer-events: none;
  }
}
