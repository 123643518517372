.timeblock {
  position: relative;
  @include fontr(14px, 24px);
  color: $black;
  padding: 18px 0;
  @include mq(bp-768){
    text-align: right;
    padding: 50px 0;
  }
  @include mq(bp-1280){
    padding: 80px 0;
  }
  &__row {
    
    @include mq(bp-1280){
      min-height: 320px;
    }
  }
  &__content {
    justify-content: flex-end;
  }
  &__title {
    display: block;
    color: $black;
    @include fontr(36px, 72px, 600);
    text-align: center;
    margin-bottom: 30px;
    @include mq(bp-1280){
      margin-bottom: 50px;
    }
  }
  &__city {
    flex: 1;
    background-color: $base-violet;
    color: #fff;
    text-transform: uppercase;
    border-radius: 50%;
    flex: 0 0 116px;
    height: 116px;
    font-size: 1.4rem;
    font-weight: 600;
    z-index: 3;
    @include mq(bp-768,max){
      display: none;
    }
  }
  &__decor {
    position: absolute;
    max-width: 33vw;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    @include mq(bp-768,max){
      display: none;
    }
  }
  &__list {
    position: relative;
    z-index: 2;
    &.no-line {
      &::after {
        display: none;
      }
    }
    &::after {
      z-index: -1;
      @include pseudo();
      width: 2px;
      height: 92%;
      background-color: #EBECF2;
      left: 0;
      top: 0;
      @include mq(bp-768){
        left: auto;
        right: 0; 
      }
    }

    li {
      margin-bottom: 28px;
      padding-left: 30px;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        @include pseudo();
        background-image: url(#{$base-path}/icons/timeline_dot.svg);
        width: 40px;
        height: 40px;
        background-size: contain;
        left: -19px;
        z-index: 2;
        top: -12px;
        @include mq(bp-768){
          top: -15px;
          width: 60px;
          height: 60px;
          right: -29px;
          left: auto;
        }
      }
      @include mq(bp-768){
        padding-right: 60px;
        &::before {
          right: -29px;
          left: auto;
        }
      }
    }
  }
  &__decorations {
    padding-left: 80px;
  
  }

  &.reverse {
   
    @include mq(bp-768){
      text-align: left;
      background-color: #FAFAFA;
      .row {
        flex-direction: row-reverse;
      }
      .timeblock__content {
        justify-content: flex-start;
      }
      .timeblock__decor {
        left: 0;
      }
      .timeblock__list {
       
        padding-right: 0;
        li {
          padding-left: 60px;
          padding-right: 0;
          &::before {
            @include mq(bp-768){
            left: -29px;
            right: auto;
            }
          }
        }
        &::after {
          right: auto;
          left: 0;
        }
        
      }
      .timeblock__decorations {
        padding-left: 0;
        padding-right: 80px;
        justify-content: flex-end;
      }
    }
   
  }

}