.footer {
  @include padding(55, 90, 'top');
  @include padding(40, 40, 'bottom');

  background-color: $navy-blue;
  overflow: hidden;
  position: relative;

  &__row {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 60px;

    @include mq(bp-992) {
      grid-template-columns: 0.25fr 0.3fr 0.45fr;
    }

    @include mq(bp-1280) {
      column-gap: 80px;
    }

    @include mq(bp-1536) {
      column-gap: 100px;
    }
  }

  &__column {
    &-center {
      @include mq(bp-992, max) {
        margin-bottom: 40px;
      }
    }

    &-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      @include mq(bp-992, max) {
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__box {
    &-contact {
      @include margin(40, 80, 'top');

      @include mq(bp-992, max) {
        text-align: center;
        margin-bottom: 40px;
      }

      a[href^='tel:'] {
        @include fontr(20px, 24px, 700);

        transition: color 0.2s;

        &:hover {
          color: $green;
        }
      }

      a[href^='mailto:'] {
        color: $green;
        transition: color 0.2s;

        &:hover {
          color: #44b160;
        }
      }
    }
  }

  &__heading {
    @include fontr(16px, 24px, 700);
    @include margin(15, 30, 'bottom');

    color: $white;

    @include mq(bp-992, max) {
      text-align: center;
    }
  }

  &__desc {
    @include fontr(12px, 14px, 400);

    color: #7c88aa;
    line-height: 1.7;

    @include mq(bp-992, max) {
      text-align: center;
      max-width: 450px;
    }

    @include mq(bp-414, max) {
      max-width: 300px;
    }
  }

  &__contact {
    @include fontr(12px, 14px, 400);

    color: $white;
    line-height: 1.5;
  }

  &__links-columns {
    @include mq(bp-768) {
      column-count: 2;
      column-gap: 20px;
    }
  }

  &__item {
    @include mq(bp-992, max) {
      text-align: center;
    }
  }

  &__link {
    @include fontr(12px, 14px, 400);

    display: inline-flex;
    color: #7c88aa;
    padding: 5px 0;
    line-height: 1.3;
    transition: color 0.2s;

    &:hover {
      color: $green;
    }
  }

  &__bottom {
    @include margin(100, 170, 'top');

    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq(bp-992, max) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__copyright {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include mq(bp-992, max) {
      margin-bottom: 50px;
    }

    @include mq(bp-768, max) {
      flex-direction: column;
      justify-content: center;
    }

    &-txt {
      @include fontr(12px, 14px, 400);

      white-space: nowrap;
      color: $white;
    }
  }

  &__logo {
    max-width: 150px;
    margin-top: 20px;

    @include mq(bp-768) {
      margin-left: 40px;
      margin-top: 0;
    }
  }

  &__created {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:hover {
      svg * {
        fill: $green;
      }
    }

    &-left {
      @include fontr(12px, 14px, 400);

      color: $white;
      margin-right: 5px;
    }

    svg * {
      transition: fill 0.2s;
    }
  }

  //Animation
  &__circles {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20%;
    left: 10%;
    pointer-events: none;

    @include mq(bp-992, max) {
      left: initial;
      top: 30%;
      right: 100%;
    }
  }

  $idx: 1;
  $numberOfCircles: 12;

  &__circle {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 9999px;
    border: 1px solid $white;
    width: 50vw;
    height: 50vw;
    opacity: 0;
    animation: 5s ease-in-out infinite;

    @include mq(bp-992, max) {
      width: 800px;
      height: 800px;
    }

    @for $circle from 1 to $numberOfCircles {
      $delay: (1-$circle/$numberOfCircles) * ($numberOfCircles/2);

      &:nth-child(n + #{$idx}) {
        animation-name: pulse#{$circle};
        animation-delay: #{$delay}s;
      }

      $idx: $idx + 1;

      @keyframes pulse#{$circle} {
        0% {
          transform: scale(0);
          opacity: 0;
          border-width: 0.1vw;
        }
        70% {
          opacity: 0.15;
          border-width: 0.05vw;
        }
        100% {
          transform: scale(1);
          opacity: 0;
          border-width: 0.05vw;
        }
      }
    }
  }
}
