$grid-columns: 12 !default;
.row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  &-reverse {
    flex-direction: row-reverse;
  }
}
.col {
  flex: 0 0 auto;
}
@for $i from 1 through $grid-columns {
  .col-xs-#{$i} {
    flex-basis: (100 / ($grid-columns / $i)) * 1%;
    max-width: (100 / ($grid-columns / $i)) * 1%;
  }
}
@media only screen and (min-width: 576px) {
  @for $i from 1 through $grid-columns {
    .col-sm-#{$i} {
      flex-basis: (100 / ($grid-columns / $i)) * 1%;
      max-width: (100 / ($grid-columns / $i)) * 1%;
    }
  }
}
@media only screen and (min-width: 768px) {
  @for $i from 1 through $grid-columns {
    .col-md-#{$i} {
      flex-basis: (100 / ($grid-columns / $i)) * 1%;
      max-width: (100 / ($grid-columns / $i)) * 1%;
    }
  }
}
@media only screen and (min-width: 992px) {
  @for $i from 1 through $grid-columns {
    .col-lg-#{$i} {
      flex-basis: (100 / ($grid-columns / $i)) * 1%;
      max-width: (100 / ($grid-columns / $i)) * 1%;
    }
  }
}
@media only screen and (min-width: 1366px) {
  @for $i from 1 through $grid-columns {
    .col-xl-#{$i} {
      flex-basis: (100 / ($grid-columns / $i)) * 1%;
      max-width: (100 / ($grid-columns / $i)) * 1%;
    }
  }
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1336px) {
    max-width: 1240px;
  }
  @media (min-width: 1440px) {
    max-width: 1380px;
  }
  @media (min-width: 1536px) {
    max-width: 1440px;
  }
  @media (min-width: 1700px) {
    max-width: 1640px;
  }
}
.container-md {
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 520px;
  }
  @media (min-width: 768px) {
    max-width: 700px;
  }
  @media (min-width: 992px) {
    max-width: 900px;
  }
  @media (min-width: 1200px) {
    max-width: 1000px;
  }
  @media (min-width: 1336px) {
    max-width: 1100px;
  }
  @media (min-width: 1440) {
    max-width: 1150px;
  }
  @media (min-width: 1536px) {
    max-width: 1200px;
  }
  @media (min-width: 1700px) {
    max-width: 1300px;
  }
}
.container-xs {
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 520px;
  }

  @media (min-width: 768px) {
    max-width: 700px;
  }

  @media (min-width: 992px) {
    max-width: 900px;
  }

  @media (min-width: 1200px) {
    max-width: 950px;
  }

  @media (min-width: 1336px) {
    max-width: 1000px;
  }

  @media (min-width: 1440) {
    max-width: 1000px;
  }

  @media (min-width: 1536px) {
    max-width: 1000px;
  }

  @media (min-width: 1700px) {
    max-width: 1050px;
  }
}
