.pricelist {
  &__container {
    width: 100%;

    @include mq(bp-992) {
      display: grid;
      grid-template-columns: 0.25fr 0.75fr;
      column-gap: 70px;
    }

    @include mq(bp-1280) {
      column-gap: 10vw;
    }
  }

  &__navigation {
    position: relative;
    margin-top: 30px;

    @include mq(bp-992, max) {
      display: none;
    }
  }

  &__nav {
    @include fontr(20px, 23px, 400);

    color: $base-violet;
    position: sticky;
    top: 180px;
    left: 0;

    &-item {
      @include margin(25, 30, 'bottom');

      &:hover {
        .pricelist__nav-link {
          font-weight: 700;
          transform: translateX(20px) translateZ(0) perspective(2px);
        }
      }
    }

    &-link {
      display: inline-flex;
      transform: translateX(0px);
      transition: all 0.3s;
      line-height: 1.25;
      will-change: font-weight;

      &--active {
        font-weight: 700;
        transform: translateX(20px) translateZ(0) perspective(2px);
      }
    }
  }

  &__box {
    @include padding(60, 130, 'top');

    @include mq(bp-414, max) {
      margin-right: -25px;
      margin-left: -25px;
    }

    &:nth-child(1) {
      padding-top: 30px;
    }

    &-title {
      text-align: left;
      margin-bottom: 5px;

      @include mq(bp-992, max) {
        text-align: center;
      }

      @include mq(bp-414, max) {
        margin-right: 25px;
        margin-left: 25px;
      }
    }

    &-subtitle {
      @include fontr(12px, 18px, 400);

      color: $black;

      @include mq(bp-992, max) {
        text-align: center;
      }

      @include mq(bp-414, max) {
        margin-right: 25px;
        margin-left: 25px;
      }
    }
  }

  &__table {
    @include margin(25, 60, 'top');

    width: 100%;

    &-row {
      @include padding(20, 28, 'top');
      @include padding(20, 28, 'bottom');
      @include padding(25, 35, 'right');

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 35px;

      &:nth-child(even) {
        background-color: #f2f2f2;
      }
    }

    &-name {
      @include fontr(14px, 19px, 400);

      padding-right: 20px;
      line-height: 1.2;
    }

    &-price {
      @include fontr(14px, 19px, 700);

      color: $green;
      white-space: nowrap;
    }
  }
}
