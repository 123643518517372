.contact {
  &__row {
    display: flex;
    position: relative;

    @include mq(bp-992, max) {
      flex-direction: column;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    @include mq(bp-414, max) {
      margin-right: -25px;
      margin-left: -25px;
    }
  }

  &__box {
    @include padding(40, 50, 'top');
    @include padding(40, 50, 'bottom');
    @include padding(30, 90, 'left');
    @include padding(30, 90, 'right');

    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: $white;
    z-index: 1;
    overflow: hidden;
    position: relative;
    background-color: #66657c;

    @include mq(bp-992, max) {
      align-items: center;
    }

    @include mq(bp-992) {
      @include margin(60, 100, 'top');
      @include margin(60, 100, 'bottom');
    }

    &-title {
      @include margin(25, 40, 'bottom');
      @include fontr(28px, 32px, 700);

      @include mq(bp-992, max) {
        text-align: center;
      }
    }

    &-heading {
      @include fontr(16px, 18px, 700);

      margin-bottom: 5px;

      @include mq(bp-992, max) {
        text-align: center;
      }
    }

    &-desc {
      @include fontr(14px, 16px, 400);

      line-height: 1.3;

      @include mq(bp-992, max) {
        text-align: center;
      }
    }

    &-row {
      @include margin(25, 40, 'top');

      display: flex;
      align-items: center;
      justify-content: space-between;

      @include mq(bp-992, max) {
        flex-direction: column;
        justify-content: center;
      }
    }

    &-content {
      a {
        transition: color 0.3s;
      }

      a:hover {
        color: $green;
      }
    }

    &-hours {
      @include margin(40, 60, 'left');

      @include mq(bp-992, max) {
        @include margin(25, 40, 'top');

        margin-left: 0;
      }
    }
  }

  &__map {
    position: relative;
    height: 300px;
    width: 100%;

    @include mq(bp-992, max) {
      order: -1;
    }

    @include mq(bp-992) {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 80%;
    }

    @include mq(bp-1280) {
      width: 75%;
    }
  }

  //Animation
  &__circles {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10%;
    right: 40%;
    transform: translate(50%, -50%) scale(0.5);
    z-index: 2;

    @include mq(bp-768, max) {
      display: none;
    }
  }

  $idx: 1;
  $numberOfCircles: 12;

  &__circle {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 9999px;
    border: 1px solid $white;
    width: 50vw;
    height: 50vw;
    opacity: 0;
    animation: 5s ease-in-out infinite;

    @include mq(bp-992, max) {
      width: 800px;
      height: 800px;
    }

    @for $circle from 1 to $numberOfCircles {
      $delay: (1-$circle/$numberOfCircles) * ($numberOfCircles/2);

      &:nth-child(n + #{$idx}) {
        animation-name: pulse#{$circle};
        animation-delay: #{$delay}s;
      }

      $idx: $idx + 1;

      @keyframes pulse#{$circle} {
        0% {
          transform: scale(0);
          opacity: 0;
          border-width: 0.1vw;
        }
        70% {
          opacity: 0.15;
          border-width: 0.05vw;
        }
        100% {
          transform: scale(1);
          opacity: 0;
          border-width: 0.05vw;
        }
      }
    }
  }
}
