.also-check {
  @include padding(60, 90, 'top');
  @include padding(60, 90, 'bottom');

  overflow: hidden;
  background-color: $gray-100;

  &__row {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 60px;

    @include mq(bp-768) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 12%;
    }
  }

  &__col {
    pointer-events: none;

    &:hover {
      .also-check__col-btn {
        transform: translateX(20px);
      }

      .also-check__col-arrow {
        transform: translateX(-10px) translateZ(0);
      }
    }

    &-title {
      @include fontr(24px, 32px, 700);

      display: inline-flex;
      margin-top: 23px;
      margin-bottom: 15px;
      cursor: pointer;
      pointer-events: initial;

      @include mq(bp-768, max) {
        text-align: center;
      }
    }

    &-link {
      @include fontr(12px, 14px, 500);
      @include tracking(50);

      display: flex;
      align-items: center;
      justify-content: center;
      color: #66657c;

      @include mq(bp-768) {
        justify-content: flex-start;
      }
    }

    &-btn {
      padding-right: 18px;
      background-color: $gray-100;
      transition: transform 0.3s;
      z-index: 2;
      pointer-events: initial;
    }

    &-arrow {
      transition: transform 0.3s;
      transform: translateX(0) translateZ(0);
    }
  }

  &__picture {
    display: flex;
    position: relative;
    max-height: 400px;
    pointer-events: initial;
    cursor: pointer;

    @include mq(bp-414) {
      width: 100%;
    }

    @include mq(bp-414, max) {
      margin-right: -25px;
      margin-left: -25px;
    }

    &::after {
      @include pseudo();

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(172, 175, 191, 0.2);
    }
  }

  &__image {
    width: 100%;
    object-fit: cover;
  }
}
