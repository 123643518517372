.cases {
  overflow: hidden;

  &__container {
    @include flex-column-center;
  }

  &__row {
    @include margin(30, 70, 'bottom');

    display: grid;
    grid-template-columns: 1fr;
    gap: 35px;

    @include mq(bp-414, max) {
      margin-right: -15px;
      margin-left: -15px;
    }

    @include mq(bp-768) {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
    }

    @include mq(bp-992) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 50px;
      row-gap: 0;
    }

    @include mq(bp-1280) {
      column-gap: 70px;
    }

    @include mq(bp-1536) {
      column-gap: 90px;
    }
  }

  &__box {
    @include flex-column-center;

    justify-content: flex-start;
    pointer-events: none;

    @include mq(bp-992) {
      align-items: flex-start;
    }

    &:hover {
      .cases__box-title {
        color: $base-violet;
      }
      .cases__box-image {
        transform: scale(1.05) perspective(2px);
      }
    }

    &-link {
      display: flex;
      width: 100%;
      align-items: flex-start;
    }

    &-picture {
      @include margin(15, 30, 'bottom');

      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      pointer-events: initial;
      min-height: 270px;
      max-height: 350px;
      height: 20vw;
      width: 100%;

      &::after {
        @include pseudo();

        width: 100%;
        height: 100%;
        background-color: rgba(172, 175, 191, 0.1);
      }
    }

    &-image {
      transform: scale(1);
      transition: transform 0.5s;
      width: 100%;
      object-fit: cover;
      align-self: stretch;
    }

    &-title {
      @include fontr(24px, 30px, 700);

      color: $green;
      line-height: 1.3;
      max-width: 85%;
      text-align: center;
      margin-bottom: 10px;
      pointer-events: initial;
      transition: color 0.3s;
    }

    &-desc {
      @include fontr(14px, 16px, 400);

      text-align: center;
      color: $black;
      line-height: 1.3;

      @include mq(bp-768, max) {
        max-width: 350px;
      }

      @include mq(bp-414, max) {
        padding-right: 15px;
        padding-left: 15px;
        max-width: initial;
      }
    }
  }
  &.cases-page {
    .cases__box-picture {
      margin-bottom: 12px;
    }
    .cases__box-title {
      max-width: 100%;
    }

    @include mq(bp-992) {
      .cases__box-desc,
      .cases__box-title {
        text-align: left;
      }
      .cases__box {
        margin-bottom: 50px;
      }
    }
    // .cases__row {
    //   margin-bottom: 0;
    // }
    .cases-footer {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-bottom: 80px;
    }
  }
}

.related {
  background-color: #ebecf2;
  &__container {
    @include flex-column-center;
  }

  &__row {
    @include margin(30, 70, 'bottom');

    display: grid;
    grid-template-columns: 1fr;
    gap: 35px;

    @include mq(bp-414, max) {
      margin-right: -15px;
      margin-left: -15px;
    }

    @include mq(bp-768) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(bp-992) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 50px;
      row-gap: 0;
    }

    @include mq(bp-1280) {
      column-gap: 70px;
    }

    @include mq(bp-1536) {
      column-gap: 90px;
    }
  }

  &__box {
    //@include flex-column-center;

    &-link {
      display: flex;
      align-items: flex-start;
      @include mq(bp-414, max) {
        width: 100%;
      }
    }

    &-picture {
      @include margin(15, 30, 'bottom');

      display: flex;
      position: relative;
      overflow: hidden;

      &:hover {
        .related__box-image {
          transform: scale(1.05);
        }
      }

      &::after {
        @include pseudo();

        width: 100%;
        height: 100%;
        background-color: rgba(172, 175, 191, 0.1);
      }
    }

    &-image {
      transition: transform 0.5s;

      @include mq(bp-414, max) {
        width: 100%;
      }
    }

    &-title {
      @include fontr(24px, 30px, 700);
      color: $black;
      line-height: 1.3;
      margin-bottom: 10px;
    }

    &-desc {
      @include fontr(14px, 16px, 400);
      color: $black;
      line-height: 1.3;

      @include mq(bp-768, max) {
        max-width: 350px;
      }

      @include mq(bp-414, max) {
        padding-right: 15px;
        padding-left: 15px;
        max-width: initial;
      }
    }
  }
}
