.swiper-container {
  width: 100%;
  height: 100%;

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #9991CC;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: $base-violet;
  }

}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 28px;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 8px;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  min-height: 500px;
  @include mq(bp-1280){
    // min-height: calc(100vh - 242px);
    padding: 0 8vw;
  }

  @include mq(bp-1920){
    min-height: calc(100vh - 265px);
  }
}

.single-slider {
  text-align: left;
  &__title {
    @include fontr(36px,96px,600);
    line-height: 1.1;
  }
  &__desc {
    display: block;
    padding: 15px 0;
    @include mq(bp-992){
      padding: 25px 0;
    }
    @include fontr(14px,24px);
    line-height: 1.5;
  }
  &__content {
    position: relative;
    z-index: 2;
    color: #333333;
    max-width: 50%;


    @include mq(bp-992, max){
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      max-width: 100%;
    }
  }
  &__image {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    img {
      width: 100vw;
      height: 100%;
      object-fit: cover; /* or object-fit: contain; */
    }
  }
  &__overlay {
    position: absolute;
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(172, 175, 191, 0.2);
  }
  &__progress {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 2px;
    transition: width .1s;
    // width: 0;
    z-index: 3;
   background-color: $green;
   box-shadow: 1px 1px 8px 0 rgba(77, 150, 96, 0.5);
    @include mq(bp-768){
      background: $base-violet;
      box-shadow: 1px 1px 8px 0 rgba(74, 58, 161, 0.5);
    }
  }
}